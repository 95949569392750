body.admin {
/*.beheer .listboxes .buttons .button-move-up,
	.beheer .listboxes .buttons .button-move-down {
		background-image: url('img/order-buttons-sprite.png');
	}*/
}
body.admin .listboxes .buttons {
width: 68px;
}
body.admin .listboxes .buttons .button.button-move-left {
background-position: bottom left;
}
body.admin .listboxes .buttons .button.button-move-up {
background-position: top right;
}
body.admin .listboxes .buttons .button.button-move-down {
background-position: bottom right;
}
body.admin .listboxes .buttons .button {
/*background-image: url('img/order-buttons-lr-sprite.png');*/
background-image: url("/css/img/order-buttons-lrud-sprite.png");
background-size: 60px;
width: 30px;
height: 30px;
display: block;
margin: 20px auto 0px;
cursor: pointer;
opacity: 1;
border: 0;
}
body.admin .page-wrapper {
overflow: unset;
}
body.admin .listboxes ::-webkit-input-placeholder {
color: #b6b6b6;
}
body.admin .listboxes :-moz-placeholder {
color: #b6b6b6;
}
body.admin .listboxes ::-moz-placeholder {
color: #b6b6b6;
}
body.admin .listboxes :-ms-input-placeholder {
color: #b6b6b6;
}
body.admin .listboxes .searchbar {
margin-bottom: 30px;
}
body.admin .listboxes .searchinput {
padding: 10px;
border-radius: 3px;
border: 1px solid #b6b6b6;
box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
width: 350px;
}
body.admin .listboxes td {
width: 47%;
}
body.admin .listboxes select {
width: 100%;
min-width: 350px !important;
height: 350px;
overflow: auto;
padding: 10px;
display: block;
margin: 5px 0px;
}
body.admin .farbtastic {
position: relative;
width: 195px;
height: 195px;
}
body.admin .farbtastic * {
position: absolute;
cursor: crosshair;
}
body.admin .farbtastic .wheel {
background: url(img/farbtasticwheel.png) no-repeat;
width: 195px;
height: 195px;
}
body.admin .farbtastic .color, body.admin .overlay1 {
top: 47px;
left: 47px;
width: 101px;
height: 101px;
}
body.admin .farbtastic .overlay1 {
background: url(img/farbtasticmask.png) no-repeat;
}
body.admin .farbtastic .marker {
width: 17px;
height: 17px;
margin: -8px 0 0 -8px;
overflow: hidden;
background: url(img/farbtasticmarker.png) no-repeat;
}
body.admin .colorpicker-row {
float: left;
margin-right: 25px;
width: 150px;
padding-bottom: 15px;
}
body.admin .colorpicker-row h2 {
width: 150px;
white-space: normal;
height: 50px;
}
body.admin .colorpicker-row + tr {
display: none;
}
body.admin .colorpicker-saturation {
width: 100px;
height: 100px;
background-image: url(img/bootstrap-colorpicker/saturation.png);
cursor: crosshair;
float: left;
}
body.admin .colorpicker-saturation i {
display: block;
height: 5px;
width: 5px;
border: 1px solid #000;
-webkit-border-radius: 5px;
-moz-border-radius: 5px;
border-radius: 5px;
position: absolute;
top: 0;
left: 0;
margin: -4px 0 0 -4px;
}
body.admin .colorpicker-saturation i b {
display: block;
height: 5px;
width: 5px;
border: 1px solid #fff;
-webkit-border-radius: 5px;
-moz-border-radius: 5px;
border-radius: 5px;
}
body.admin .colorpicker-alpha, body.admin .colorpicker-hue {
width: 15px;
height: 100px;
float: left;
cursor: row-resize;
margin-left: 4px;
margin-bottom: 4px;
}
body.admin .colorpicker-alpha i, body.admin .colorpicker-hue i {
display: block;
height: 1px;
background: #000;
border-top: 1px solid #fff;
position: absolute;
top: 0;
left: 0;
width: 100%;
margin-top: -1px;
}
body.admin .colorpicker-hue {
background-image: url(img/bootstrap-colorpicker/hue.png);
}
body.admin .colorpicker-alpha {
background-image: url(img/bootstrap-colorpicker/alpha.png);
display: none;
}
body.admin .colorpicker-alpha, body.admin .colorpicker-hue, body.admin .colorpicker-saturation {
background-size: contain;
}
body.admin .colorpicker {
padding: 4px;
min-width: 130px;
margin-top: 1px;
-webkit-border-radius: 4px;
-moz-border-radius: 4px;
border-radius: 4px;
z-index: 2500;
}
body.admin .colorpicker:after, body.admin .colorpicker:before {
display: table;
content: "";
line-height: 0;
}
body.admin .colorpicker:after {
clear: both;
}
body.admin .colorpicker:before {
content: "";
display: inline-block;
border-left: 7px solid transparent;
border-right: 7px solid transparent;
border-bottom: 7px solid #ccc;
border-bottom-color: rgba(0, 0, 0, 0.2);
position: absolute;
top: -7px;
left: 6px;
}
body.admin .colorpicker:after {
content: "";
display: inline-block;
border-left: 6px solid transparent;
border-right: 6px solid transparent;
border-bottom: 6px solid #fff;
position: absolute;
top: -6px;
left: 7px;
}
body.admin .colorpicker div {
position: relative;
}
body.admin .colorpicker.colorpicker-with-alpha {
min-width: 140px;
}
body.admin .colorpicker.colorpicker-with-alpha .colorpicker-alpha {
display: block;
}
body.admin .colorpicker-color {
height: 10px;
margin-top: 5px;
clear: both;
background-image: url(img/bootstrap-colorpicker/alpha.png);
background-position: 0 100%;
}
body.admin .colorpicker-color div {
height: 10px;
}
body.admin .colorpicker-selectors {
display: none;
height: 10px;
margin-top: 5px;
clear: both;
}
body.admin .colorpicker-selectors i {
cursor: pointer;
float: left;
height: 10px;
width: 10px;
}
body.admin .colorpicker-selectors i + i {
margin-left: 3px;
}
body.admin .colorpicker-element .add-on i, body.admin .colorpicker-element .input-group-addon i {
display: inline-block;
cursor: pointer;
height: 16px;
vertical-align: text-top;
width: 16px;
}
body.admin .colorpicker.colorpicker-inline {
position: relative;
display: inline-block;
float: none;
z-index: auto;
}
body.admin .colorpicker.colorpicker-horizontal {
width: 110px;
min-width: 110px;
height: auto;
}
body.admin .colorpicker.colorpicker-horizontal .colorpicker-saturation {
margin-bottom: 4px;
}
body.admin .colorpicker.colorpicker-horizontal .colorpicker-color {
width: 100px;
}
body.admin .colorpicker.colorpicker-horizontal .colorpicker-alpha, body.admin .colorpicker.colorpicker-horizontal .colorpicker-hue {
width: 100px;
height: 15px;
float: left;
cursor: col-resize;
margin-left: 0;
margin-bottom: 4px;
}
body.admin .colorpicker.colorpicker-horizontal .colorpicker-alpha i, body.admin .colorpicker.colorpicker-horizontal .colorpicker-hue i {
display: block;
height: 15px;
background: #fff;
position: absolute;
top: 0;
left: 0;
width: 1px;
border: none;
margin-top: 0;
}
body.admin .colorpicker.colorpicker-horizontal .colorpicker-hue {
background-image: url(img/bootstrap-colorpicker/hue-horizontal.png);
}
body.admin .colorpicker.colorpicker-horizontal .colorpicker-alpha {
background-image: url(img/bootstrap-colorpicker/alpha-horizontal.png);
}
body.admin .colorpicker-right:before {
left: auto;
right: 6px;
}
body.admin .colorpicker-right:after {
left: auto;
right: 7px;
}
body.admin .colorpicker-no-arrow:before {
border-right: 0;
border-left: 0;
}
body.admin .colorpicker-no-arrow:after {
border-right: 0;
border-left: 0;
}
body.admin .colorpicker-alpha.colorpicker-visible, body.admin .colorpicker-hue.colorpicker-visible, body.admin .colorpicker-saturation.colorpicker-visible, body.admin .colorpicker-selectors.colorpicker-visible, body.admin .colorpicker.colorpicker-visible {
display: block;
}
body.admin .colorpicker-alpha.colorpicker-hidden, body.admin .colorpicker-hue.colorpicker-hidden, body.admin .colorpicker-saturation.colorpicker-hidden, body.admin .colorpicker-selectors.colorpicker-hidden, body.admin .colorpicker.colorpicker-hidden {
display: none;
}
body.admin .colorpicker-inline.colorpicker-visible {
display: inline-block;
}
body.admin .codesTable {
float: left;
}
body.admin .codesTable td {
border: 1px solid #ccc;
padding: 5px;
}
body.admin .codesTable td.green {
background: #b8ffce;
}
body.admin .codesTable td.red {
background: #ffbdbd;
}
body.admin .codesTable + .exportBtn {
float: left;
margin: 16px;
}
body.admin .viewDocOpmerkingen .title {
font-weight: bold;
}
body.admin input[type="number"].licenseDuration {
width: 65px;
height: 35px;
margin: 0;
font-family: "Lato-Regular", sans-serif;
cursor: pointer;
-webkit-appearance: none;
-moz-appearance: none;
-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
-moz-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
border: 0;
border: 1px solid #999;
text-align: center;
-webkit-border-radius: 3px;
-moz-border-radius: 3px;
-ms-border-radius: 3px;
border-radius: 3px;
}
body.admin .licenseLinkItems .licenseLink .linkMonths {
line-height: 12px;
font-family: "brown-bold", sans-serif;
font-weight: normal;
}
body.admin .ui-select.licenseSelector {
width: calc(100% - 70px);
float: right;
}
body.admin .icon.icon-kortingscode-beheer:before {
content: "";
}
body.admin .icon.icon-product-beheer:before {
content: "";
}
body.admin .icon.icon-product-looptijd-beheer:before {
content: "";
}
body.admin .icon.icon-diploma-beheer:before {
content: "";
}
body.admin .icon.icon-webshop-filters:before {
content: "";
}
body.admin .icon.icon-praktijkbewijs-beheer:before {
content: "";
}
body.admin .icon.icon-nieuws-beheer:before {
content: "";
}
body.admin .icon.icon-nieuwsbrief-beheer:before {
content: "";
}
body.admin .icon.icon-landen-beheer:before {
content: "";
}
body.admin .icon.icon-redirect-beheer:before {
content: "";
}
body.admin .icon.icon-zoekbeheer:before {
content: "";
}
body.admin .icon.icon-examen-beheer:before {
content: "";
}
body.admin .icon.icon-projectexamen-beheer:before {
content: "";
}
body.admin .icon.icon-c4-examen-beheer:before {
content: "";
}
body.admin .icon.icon-documents:before {
content: "";
}
body.admin .icon.icon-contactbeheer:before {
content: "";
}
body.admin .icon.icon-registers_admin:before {
content: "";
}
body.admin .icon.icon-loginpage:before {
content: "";
}
body.admin .icon.icon-pyramides:before {
content: "";
}
body.admin .icon.icon-errorpage:before {
content: "";
}
body.admin .icon.icon-payoffs:before {
content: "";
}
body.admin .icon.icon-view-document:before {
content: "";
}
body.admin .icon.icon-faq-beheer:before {
content: "";
}
body.admin .icon.icon-pagina-beheer:before {
content: "";
}
body.admin .icon.icon-languages:before {
content: "";
}
@media only screen and (min-width: 992px) {
body.admin .column-mdh-1 {
	width: 4.1666666667%;
}
body.admin .column-mdh-2 {
	width: 8.3333333333%;
}
body.admin .column-mdh-3 {
	width: 12.5%;
}
body.admin .column-mdh-4 {
	width: 16.6666666667%;
}
body.admin .column-mdh-5 {
	width: 20.8333333333%;
}
body.admin .column-mdh-6 {
	width: 25%;
}
body.admin .column-mdh-7 {
	width: 29.1666666667%;
}
body.admin .column-mdh-8 {
	width: 33.3333333333%;
}
body.admin .column-mdh-9 {
	width: 37.5%;
}
body.admin .column-mdh-10 {
	width: 41.6666666667%;
}
body.admin .column-mdh-11 {
	width: 45.8333333333%;
}
body.admin .column-mdh-12 {
	width: 50%;
}
body.admin .column-mdq-1 {
	width: 2.0833333333%;
}
body.admin .column-mdq-2 {
	width: 4.1666666667%;
}
body.admin .column-mdq-3 {
	width: 6.25%;
}
body.admin .column-mdq-4 {
	width: 8.3333333333%;
}
body.admin .column-mdq-5 {
	width: 10.4166666667%;
}
body.admin .column-mdq-6 {
	width: 12.5%;
}
body.admin .column-mdq-7 {
	width: 14.5833333333%;
}
body.admin .column-mdq-8 {
	width: 16.6666666667%;
}
body.admin .column-mdq-9 {
	width: 18.75%;
}
body.admin .column-mdq-10 {
	width: 20.8333333333%;
}
body.admin .column-mdq-11 {
	width: 22.9166666667%;
}
body.admin .column-mdq-12 {
	width: 25%;
}
}
body.admin .readonly-field {
opacity: 0.5;
pointer-events: none;
background: #cacaca;
}
body.admin table.documentstbl th {
cursor: pointer;
white-space: nowrap;
}
body.admin table.documentstbl th div {
display: inline-block;
min-width: 10px;
position: relative;
top: 6px;
margin-left: 5px;
}
body.admin table.documentstbl th.sorted div {
width: 0;
height: 0;
border-style: solid;
border-width: 0 5px 5px 5px;
border-color: transparent transparent #008ad1 transparent;
}
body.admin table.documentstbl th.sortedInverse div {
width: 0;
height: 0;
border-style: solid;
border-width: 5px 5px 0 5px;
border-color: #008ad1 transparent transparent transparent;
}
body.admin .dateSelectionDatepicker {
display: inline-block;
}
body.admin .dateSelectionDates {
display: inline-block;
margin-left: 15px;
max-width: calc(100% - 250px);
}
body.admin .dateSelectionDates input {
margin: 5px;
width: 112px;
text-align: center;
}
body.admin .ui-state-highlight a.ui-state-active {
color: #ffffff !important;
}
body.admin .form-group .form-group-input.inputcode, body.admin .form-group .form-group-select.inputcode {
display: inline-block;
width: 220px;
margin-right: 5px;
margin-top: 1px;
}
body.admin a.btn.generateCode, body.admin .btn.generateCode {
display: inline-block;
}
body.admin a, body.admin a:link, body.admin a:visited {
text-decoration: none;
}
body.admin .table-list .list-row .list-cell.small .btn.btn-inline.btn-icon-sm {
margin-top: 2px;
}
body.admin .table-list .list-row .list-cell.small .mover .icon {
top: 0px;
}
body.admin .entrySelect .cbPageItem.remove {
margin-left: 6px;
margin-right: 12px;
}
body.admin .entrySelect .mover {
font-size: 1rem;
}
body.admin .entrySelect .mover .icon {
top: -4px;
}
body.admin .breadcrumb a, body.admin .breadcrumb a:link, body.admin .breadcrumb a:visited {
color: #008ad1;
}
body.admin .sidebar ul.sidebar-list {
padding: 0;
}
body.admin .sidebar ul.sidebar-list li {
padding: 0;
}
body.admin .page-wrapper {
background-color: unset;
}